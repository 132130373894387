<template>
  <div>
    <Header ref="header"/>

    <div class="homeindex">
      <div class="content" style="min-height: 900px;">
        <div style="height: 30px"></div>
        <div class="visual-item w1200" style="display: block;">

          <div class="view" >
            <!--            <el-alert-->
            <!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
            <!--                type="success"-->
            <!--                effect="dark" style="margin-bottom: 20px">-->
            <!--            </el-alert>-->

            <!--            <el-alert-->
            <!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
            <!--                type="info"-->
            <!--                effect="dark" style="margin-bottom: 20px">-->
            <!--            </el-alert>-->

            <!--            <el-alert-->
            <!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
            <!--                type="warning"-->
            <!--                effect="dark" style="margin-bottom: 20px">-->
            <!--            </el-alert>-->

            <!--            <el-alert-->
            <!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
            <!--                type="error"-->
            <!--                effect="dark" style="margin-bottom: 20px" >-->
            <!--            </el-alert>-->
          </div>

          <div class="view" style="margin-bottom: 10px;">
            <img src="http://image.yabyy.com/home/jh/d1.png">
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[0].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[0].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[1].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[1].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[2].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[2].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[3].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[3].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[4].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[4].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[5].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[5].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[6].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[6].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[7].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[7].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view" style="margin-bottom: 10px;">
            <img src="http://image.yabyy.com/home/jh/d2.png">
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[8].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[8].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[9].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[9].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[10].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[10].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[11].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[11].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[12].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[12].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[13].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[13].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[14].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[14].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[15].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[15].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'
export default {
  name: "nvshen",
  components: {
    Header,
    Footer
  },
  mounted() {
    this.$refs.header.hideCate();
  },
  data() {
    return {
      Floor:[

        {'index':0,'url':'http://image.yabyy.com/home/jh/1000599.png','id':'1000599','search':''},      //16784	1034487	西洋参
        {'index':1,'url':'http://image.yabyy.com/home/jh/1000726.png','id':'1000726','search':''},       //10881	1009785	燕窝 白燕盏
        {'index':2,'url':'http://image.yabyy.com/home/jh/1000728.png','id':'1000728','search':''},     //12872	1013606	川贝母
        {'index':3,'url':'http://image.yabyy.com/home/jh/1009540.png','id':'1009540','search':''},      //12974	1013873	红参
        {'index':4,'url':'http://image.yabyy.com/home/jh/1010814.png','id':'1010814','search':''},     //17073	1035225	铁皮石斛
        {'index':5,'url':'http://image.yabyy.com/home/jh/1013137.png','id':'1013137','search':''},      //18686	1038089	冬虫夏草(礼盒)
        {'index':6,'url':'http://image.yabyy.com/home/jh/1040896.png','id':'1040896','search':''},      //19440	1038092	海参(60头)(礼盒)
        {'index':7,'url':'http://image.yabyy.com/home/jh/1040897.png','id':'1040897','search':''},      //16938	1035221	陈皮(15年份)

        {'index':8,'url':'http://image.yabyy.com/home/jh/1000186.png','id':'1000186','search':''},      //10876	1009780	燕窝(白燕盏)
        {'index':9,'url':'http://image.yabyy.com/home/jh/1002083.png','id':'1002083','search':''},      //13685	1015199	燕窝
        {'index':10,'url':'http://image.yabyy.com/home/jh/1002241.png','id':'1002241','search':''},      //10879	1009783	燕窝)(白燕盏)
        {'index':11,'url':'http://image.yabyy.com/home/jh/1002661.png','id':'1002661','search':''},      //10880	1009784	燕窝(白燕盏)
        {'index':12,'url':'http://image.yabyy.com/home/jh/1006359.png','id':'1006359','search':''},      //16935	1035217	西洋参(罐装)
        {'index':13,'url':'http://image.yabyy.com/home/jh/1008431.png','id':'1008431','search':''},      //16936	1035218	西洋参
        {'index':14,'url':'http://image.yabyy.com/home/jh/1010034.png','id':'1010034','search':''},      //10922	1009851	西洋参
        {'index':15,'url':'http://image.yabyy.com/home/jh/1013393.png','id':'1013393','search':''},      //20785	1041481	西洋参

      ]
    }
  }
}
</script>

<style scoped>
.rec-store-item .p-img {
  height: 381px;
}
</style>
